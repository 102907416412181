
// @ts-ignore
import VueCircle from 'vue2-circle-progress';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { UserDrop } from '@/types/User';

@Component({
  components: {
    VueCircle
  }
})
export default class UpgradePlay extends Vue {
  @Prop({type: Boolean, default: false}) isPlayActive: boolean;
  @Prop({type: Boolean, default: false}) isUpgradeInProgress: boolean;
  @Prop({type: Number, default: 0}) chance: number;
  @Prop({type: Number, default: 0}) amount: number;
  @Prop({type: Function, required: true}) createUpgradeFn: () => Promise<UserDrop | null | undefined>;
  upgradeResult: boolean | null = null;
  flipped: boolean = false;
  previousChance: number = null;
  refCircleKeyHack: string = Math.random().toString();

  rollArrowTo: number = 0;
  @Ref('chanceCircle') readonly chanceCircle: VueCircle;

  get startAngle() {
    return this.flipped ? Math.PI / 2 : Math.PI * 1.5;
  }

  get percentToAngle() {
    return this.chance / 100 * 360;
  }

  rollResult(wonDrop: UserDrop | null) {
    this.previousChance = this.chance;

    let min, max: number;
    switch (true) {
      case (!this.flipped && !!wonDrop):
        min = 3;
        max = this.percentToAngle - 3;
        break;

      case (!this.flipped && !wonDrop):
        min = this.percentToAngle + 3;
        max = 357;
        break;

      case (this.flipped && !!wonDrop):
        min = 183;
        max = 180 + this.percentToAngle - 3;
        break;

      case (this.flipped && !wonDrop):
        min = 180 + this.percentToAngle + 3;
        max = 537;
        break;
    }

    this.rollArrowTo = Math.floor(Math.random() * (max - min) + min);

    setTimeout(() => {
      this.upgradeResult = !!wonDrop;
      this.$emit('playRolled', wonDrop);
    }, 3000);
  }

  async createUpgrade() {
    const wonDrop = await this.createUpgradeFn();
    if (wonDrop !== undefined) {
      this.rollResult(wonDrop);
    }
  }

  flip() {
    if (!this.isUpgradeInProgress) {
      this.flipped = !this.flipped;
    }
  }

  reset() {
    this.upgradeResult = null;
    this.previousChance = null;
    this.rollArrowTo = 0;
    this.$emit('reset');
  }

  get chanceToShow() {
    return this.upgradeResult === null ? this.chance : this.previousChance;
  }

  @Watch('chanceToShow')
  onChanceChange() {
    if (this.upgradeResult === null) {
      // @ts-ignore
      this.$refs.chanceCircle.updateProgress(this.chance);
      this.rollArrowTo = 0;
    }
  }

  @Watch('flipped')
  onFlippedChange() {
    this.refCircleKeyHack = Math.random().toString();
  }
}
