import Axios from 'axios';

import { MOCK_OPEN_CONTRACT } from '../Contract/mockOpenContract';
import { CompletedTaskNotificationResponse } from '@/requests/types';
import { UserAccount, UserDrop } from '@/types/User';

export interface CreateUpgradeResponse {
    error?: string;
    user?: Partial<UserAccount>;
    wonDrop?: UserDrop | null;
    eventCompletedTaskNotification?: CompletedTaskNotificationResponse;
}

interface UpgradeBetData {
    betDropIds: number[];
    betBalance: number;
    betMana: number;
}

export default async function createUpgrade(
    {betDropIds, betBalance, betMana}: UpgradeBetData,
    selectedItemId: number
): Promise<CreateUpgradeResponse> {
    if (process.env.NODE_ENV === 'development') {
        const randNum = Math.floor(Math.random() * 10 + 1);
        return {...MOCK_OPEN_CONTRACT, wonDrop: randNum < 5 ? null : MOCK_OPEN_CONTRACT.wonDrop};
    }

    return (await Axios.post<CreateUpgradeResponse>('/api/upgrade', {
        betDropIds, betBalance, betMana, selectedItemId
    })).data;
}
