import { CreateContractResponse } from '@/requests/Contract/createContract';

export const MOCK_OPEN_CONTRACT: CreateContractResponse = {
    wonDrop: {
        item: {
            id: 1,
            name: 'Swooord Sword Sword Sword and Sword',
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 200,
            color: 'e4ae39',
            type: 'item',
            market_hash_name: 'Swooord Sword Sword Sword and Sword',
        },
        source: 'contract',
        id: 22,
        status: 0
    },
    user: {
        balance: 10,
        mana: 2000
    }
};
