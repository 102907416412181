import { render, staticRenderFns } from "./Upgrade.vue?vue&type=template&id=04995b4e&scoped=true&"
import script from "./Upgrade.vue?vue&type=script&lang=ts&"
export * from "./Upgrade.vue?vue&type=script&lang=ts&"
import style0 from "./Upgrade.vue?vue&type=style&index=0&id=04995b4e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04995b4e",
  null
  
)

export default component.exports