import { Drop } from '@/types/Drop';

export const MOCK_GET_DROPS: Drop[] = [
  {
    id: 277981,
    user: {
      uuid: '4b4209d3-e97e-4a3c-a5e3-14f1b1627013',
      name: 'kex Predator',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
      price: 13,
      color: 'e4ae39',
      type: 'item'
    },
    source: 'upgrade',
  },
  {
    id: 277224,
    user: {
      uuid: '5e11e7cd-bcfc-43ae-879b-6ae7dac0d800',
      name: 'darkmoong61',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 4,
      color: 'ade55c',
      type: 'item'
    },
    case: { id: 19, image: 'clarity_1649712782194.png', name: '' },
    source: 'case',
  },
  {
    id: 277223,
    user: {
      uuid: '5e11e7cd-bcfc-43ae-879b-6ae7dac0d800',
      name: 'darkmoong61',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcU2uxRKA0jCRuOi2dzHXEh3LTtAs7u1JAkv1-HJdjJM_8i5gYODqODxMqvemCUCvpwl2uySoYn0iQy18hJuZ22ldY-TIwQ2MwqGrlLoxOvogse6vsjN1zI97UnK296M',
      price: 2,
      color: '4b69ff',
      type: 'item'
    },
    case: { id: 19, image: 'clarity_1649712782194.png', name: '' },
    source: 'case',
  },
  {
    id: 277222,
    user: {
      uuid: '5e11e7cd-bcfc-43ae-879b-6ae7dac0d800',
      name: 'darkmoong61',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXP7g1bJ4Q1lgheXknVSffi0tvWQVN1KTtRv6igFAtv0vfBfzp9_9-kkYWF2fOjYrqDwDhSuZwl3rHDpomi31LjqhJqMDrzIYOTcVNsY1mC_Ve-levxxcjrlrlK9gw',
      price: 7,
      color: '4b69ff',
      type: 'item'
    },
    case: { id: 19, image: 'clarity_1649712782194.png', name: '' },
    source: 'case',
  },
  {
    id: 277221,
    user: {
      uuid: '5e11e7cd-bcfc-43ae-879b-6ae7dac0d800',
      name: 'darkmoong61',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUivB9aSQPdQuW56MvBUlpzIBYYu7-kPzhkwfLPdjhQot_hloSPlvWiZb6HwzJT7pQi27DEpN-ti1XkqEc_YWCgdYbGcAE_YF_Oug_ps3o_ufY',
      price: 13,
      color: '4b69ff',
      type: 'item'
    },
    case: { id: 26, image: 'фарм уровней_1663200958238.png', name: '' },
    source: 'case',
  },
  {
    id: 277220,
    user: {
      uuid: '95687226-6c59-4a21-896a-07ff116d069c',
      name: 'h1T',
      avatar:
          'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUzsR4STVTVeOms2dvfVmJ7IztDvr-aKA5u1_baTj9D_tO424GTksj5MrXFmm5u58NOnuHEyo_8hlCxuSw-MiusepjBcgQ7MFzZrlm5xue71pfu6pjNy3VgvXNz7Cvfmha-iR5LauM906eXVxzAUEorxz1A',
      price: 100,
      color: 'e4ae39',
      type: 'item'
    },
    source: 'contract'
  },
  {
    id: 277219,
    user: {
      uuid: '8e3321ee-d1fc-48bd-9d4b-939c8e0f6ab1',
      name: 'NextWeekFlower',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUloBVZXlneSeG_mNvaBWJyJAhUubSaKQh3nOfBJwJE7dC1m460lfjjDLbekm5dppIniLrAo9r3iQOyqkI9YG-hLNKXIwM_MFmD_ge6lOi71MW9vZyaynt9-n5107dN46g',
      price: 82,
      color: 'e4ae39',
      type: 'item'
    },
    source: 'gift'
  },
  {
    id: 277218,
    user: {
      uuid: 'dfa0cd70-f152-4dac-8c91-db0c04f7779a',
      name: 'Маргаретти',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcU_qBxTTVjRUvbixMfcUFZLKgJoorKgFAZuxfrEPi5K49-9q4-NqOP8NoTQmH1Y5IsmiL-UpI2m2Abk_xZvMDyld4SQcVVoYV7Tq1Hqxu3r15a56pvIynNrpGB8ssEKKGf1',
      price: 80,
      color: 'e4ae39',
      type: 'item'
    },
    case: { id: 35, image: 'гифт_1627057665359.png', name: '' },
    source: 'case'
  },
  {
    id: 277217,
    user: {
      uuid: '287cbee1-fbfc-44c4-b026-b731d2454355',
      name: 'HATTORI',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUzsR4STVTVeOms2dvfVmJ7IztDvr-aKA5u1_baTj9D_tO424GTksj5MrXFmm5u58NOnuHEyo_8hlCxuSw-MiusepjBcgQ7MFzZrlm5xue71pfu6pjNy3VgvXNz7Cvfmha-iR5LauM906eXVxzAUEorxz1A',
      price: 100,
      color: 'e4ae39',
      type: 'item'
    },
    case: { id: 35, image: 'гифт_1627057665359.png', name: '' },
    source: 'case'
  },
  {
    id: 277216,
    user: {
      uuid: 'cf5ee5c6-9231-413e-bd13-be15a170f760',
      name: 'ŁÍŁ ŞÍŅ',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxoRpSX3PbSe2q39ucUFV1KhdovbSsLA907OfBJgJR5NWzmITElP_1PKjunWVY781ltf3IorPmgF2xpxcDNDavcJjAJFdtZlDSqFC2we7rhce4787MmyQxviEg4naLykO01xpOaLc6g_CdVxzAUEo9Gmgn',
      price: 91,
      color: 'D2D2D2',
      type: 'item'
    },
    case: { id: 35, image: 'гифт_1627057665359.png', name: '' },
    source: 'case'
  },
  {
    id: 277215,
    user: {
      uuid: '2f0f17df-c7bf-40bd-b0a8-1e85af30a997',
      name: 'Nikita Gudozhnikov',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcU_qBxTTVjRUvbixMfcUFZLKgJoorKgFAZuxfrEPi5K49-9q4-NqOP8NoTQmH1Y5IsmiL-UpI2m2Abk_xZvMDyld4SQcVVoYV7Tq1Hqxu3r15a56pvIynNrpGB8ssEKKGf1',
      price: 80,
      color: 'e4ae39',
      type: 'item'
    },
    case: { id: 35, image: 'гифт_1627057665359.png', name: '' },
    source: 'case'
  },
  {
    id: 277214,
    user: {
      uuid: '09bd37ad-41a7-4446-81b0-e6b7f9f1e0bc',
      name: 'toksyyy',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUzsR4STVTVeOms2dvfVmJ7IztDvr-aKA5u1_baTj9D_tO424GTksj5MrXFmm5u58NOnuHEyo_8hlCxuSw-MiusepjBcgQ7MFzZrlm5xue71pfu6pjNy3VgvXNz7Cvfmha-iR5LauM906eXVxzAUEorxz1A',
      price: 100,
      color: 'e4ae39',
      type: 'item'
    },
    case: { id: 35, image: 'гифт_1627057665359.png', name: '' },
    source: 'case'
  },
  {
    id: 277213,
    user: {
      uuid: '1e7d39a9-267d-4f3c-bca9-f1f9479e865a',
      name: 'i i i i i i i i i i i i i i i i',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUgqAFSXgPDQvKoxcbdVGJ4JBdf-begOA8vwPbedC9L4tuJmIGYn7mtZOOBzjkA6pIi3rnErYmn3ADg_0NtMWqlJIeRe1I_Zw6ErlS3wbzqm9bi6725VCfe',
      price: 87,
      color: 'e4ae39',
      type: 'item'
    },
    source: 'contract'
  },
  {
    id: 277212,
    user: {
      uuid: '85bf001e-fa28-4bf1-b5e8-e5d8d81dd34f',
      name: 'tylikv228',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUgqAFSXgPDQvKoxcbdVGJ4JBdf-begOA8vwPbedC9L4tuJmIGYn7mtZOOBzjkA6pIi3rnErYmn3ADg_0NtMWqlJIeRe1I_Zw6ErlS3wbzqm9bi6725VCfe',
      price: 87,
      color: 'e4ae39',
      type: 'item'
    },
    source: 'gift'
  },
  {
    id: 277211,
    user: {
      uuid: '534a14ad-5c7a-4db3-8ad0-a3c86b23cf83',
      name: '9к бустер',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxoRpSX3PbSe2q39ucUFV1KhdovbSsLA907OfBJgJR5NWzmITElP_1PKjunWVY781ltf3IorPmgF2xpxcDNDavcJjAJFdtZlDSqFC2we7rhce4787MmyQxviEg4naLykO01xpOaLc6g_CdVxzAUEo9Gmgn',
      price: 91,
      color: 'D2D2D2',
      type: 'item'
    },
    source: 'contract'
  },
  {
    id: 277210,
    user: {
      uuid: 'd584f9a2-352b-4069-896a-f88268ba5f0b',
      name: 'Andrey Smirnov',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUzsR4STVTVeOms2dvfVmJ7IztDvr-aKA5u1_baTj9D_tO424GTksj5MrXFmm5u58NOnuHEyo_8hlCxuSw-MiusepjBcgQ7MFzZrlm5xue71pfu6pjNy3VgvXNz7Cvfmha-iR5LauM906eXVxzAUEorxz1A',
      price: 100,
      color: 'e4ae39',
      type: 'item'
    },
    case: { id: 35, image: 'гифт_1627057665359.png', name: '' },
    source: 'gift'
  },
  {
    id: 277209,
    user: {
      uuid: 'fd8d6ae8-a212-4387-8a96-dcaaaa77adfe',
      name: 'stisik',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUgvBlUT0efRPa0xNvSX1F9KwFov7SsPw5vnPDaaC5W7dC6nY6OqP76Oq_YmSUB68EjjLnFoI3z2ATsrUU5YzigI4OUJ1dvYwnV-QS_xObphJDo6s7K1zI97Vef6RgJ',
      price: 2,
      color: '4b69ff',
      type: 'item'
    },
    case: { id: 35, image: 'гифт_1627057665359.png', name: '' },
    source: 'gift'
  },
  {
    id: 277208,
    user: {
      uuid: '0f3e6d84-4bb4-43b4-8109-c8db24cb2797',
      name: 'Frostbrew',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcU4vBxaSV7eRvG5mMXGVFpLMQ0P-bCwLABfx_qQTi5V486yxc7fwKSgN-jQlDJSvsEo077H9Nikjgeyr0pkN2mgdobHcQJoYFDRrlDv366x0jztvdec',
      price: 116,
      color: 'e4ae39',
      type: 'item'
    },
    case: { id: 35, image: 'гифт_1627057665359.png', name: '' },
    source: 'gift'
  },
  {
    id: 277207,
    user: {
      uuid: '04c5b9ee-eaa0-417f-ad76-6c13e1503891',
      name: 'еблан',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUgoBBUQU3CUqu_3sTabEl9fTteu7eqORNh38zAdDxGo86_n4m0g_6sDLLcm2RD_MR9teHE9IikxgDlrRZkYzzzctPHcw49NQ3T-1Hol-br0JDtv5XPn3Flsydz43yInhKpwUYbJD3RFbM',
      price: 15,
      color: 'e4ae39',
      type: 'item'
    },
    case: { id: 35, image: 'гифт_1627057665359.png', name: '' },
    source: 'gift'
  },
  {
    id: 277206,
    user: {
      uuid: 'fd8d6ae8-a212-4387-8a96-dcaaaa77adfe',
      name: 'stisik',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcU2uxRKA0jCRuOi2dzHXEh3LTtAs7u1JAkv1-HJdjJM_8i5gYODqODxMqvemCUCvpwl2uySoYn0iQy18hJuZ22ldY-TIwQ2MwqGrlLoxOvogse6vsjN1zI97UnK296M',
      price: 2,
      color: '4b69ff',
      type: 'item'
    },
    case: { id: 35, image: 'гифт_1627057665359.png', name: '' },
    source: 'gift'
  }
];
