
import { Component, Vue } from 'vue-property-decorator';
import PadButton from '@/components/Buttons/PadButton.vue';
import EmptyInventoryButtons from '@/components/DropInventory/EmptyInventory/EmptyInventoryButtons.vue';
import LoginGeneralButton from '@/components/Buttons/LoginGeneralButton.vue';

@Component({
  components: {EmptyInventoryButtons, PadButton, LoginGeneralButton}
})
export default class UpgradeEmptyInventory extends Vue {

  get user() {
    return this.$store.getters['user/isAuthorized'] ? this.$store.state.user : null;
  }

}
