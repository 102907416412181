
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Item as IItem } from '@/types/Item';
import Item from '@/components/Item/Item.vue';
import ItemImage from '@/components/Item/ItemImage.vue';
import getUpgradeItem from '@/requests/Upgrade/getUpgradeItem';
import { UserDrop } from '@/types/User';

@Component({
  components: { ItemImage, Item },
})
export default class SelectedUpgradeItem extends Vue {
  @Prop({ default: null }) item: Partial<IItem> | null;
  @Prop({ required: true }) upgradeDropsAmount: number;
  @Prop({ required: true }) isActive: boolean;
  @Prop({ default: null }) upgradeResult: boolean | null;
  tempCoefficient: number | null = null;
  isLoadingItem: boolean = false;

  get itemCoefficient() {
    if (!this.item || !this.upgradeDropsAmount) return;

    return Number((this.item.price / this.upgradeDropsAmount).toFixed(2));
  }

  async getItemByCoefficient(coefficient: number) {
    try {
      if (!this.upgradeDropsAmount || this.isLoadingItem || !this.isActive)
        return;

      this.isLoadingItem = true;
      this.$emit(
        'selectItem',
        await getUpgradeItem(this.upgradeDropsAmount, coefficient)
      );
    } catch (e) {
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'warning',
        title: 'Апгрейд',
        text: 'Неизвестная ошибка!',
      });
    } finally {
      this.isLoadingItem = false;
    }
  }

  get coefficient() {
    return !!this.tempCoefficient ? this.tempCoefficient : this.itemCoefficient;
  }

  isNearCoefficient(val: number) {
    if (!this.coefficient) return false;

    return val > this.coefficient * 0.9 && val < this.coefficient * 1.1;
  }

  @Watch('upgradeResult')
  handleUpgradeResult(val, prev) {
    if (val === null && typeof prev === 'boolean') {
      this.tempCoefficient = undefined;
    }
  }

  @Watch('itemCoefficient')
  handleItemCoefficientChange(val, prev) {
    if (!val && prev && this.upgradeResult !== null) {
      this.tempCoefficient = prev;
    }
  }
}
