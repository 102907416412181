import Axios from 'axios';

import { UserDrop } from '@/types/User';
import { MOCK_INVENTORY } from '@/requests/Inventory/getInventory/mockGetInventory';
import axiosRetry from 'axios-retry';

export type InventoryFilterKeys = 'active' | 'withdrawn';
export type InventorySortKeys = 'priceDesc' | 'dateDesc';

interface GetInventoryResponse {
    data: UserDrop[];
    total: number;
}

axiosRetry(Axios, {retries: 4, retryDelay: axiosRetry.exponentialDelay});

export default async function getInventory(userId: string, filterBy: InventoryFilterKeys, visibility: string, source: string, sort: string, minPrice: number, offset: number, limit: number, filterFreeCaseDrops: boolean, filterActiveWithdraws: boolean, upgradeDropsAmount: number): Promise<GetInventoryResponse> {
    if (visibility === 'upgrade') {
        if (process.env.NODE_ENV === 'development') {
            const max = upgradeDropsAmount * 10;
            const min = upgradeDropsAmount * 1.2;

            const data = MOCK_INVENTORY.map((drop, index) => ({
                ...drop,
                id: index,
                item: {...drop.item, price: Math.floor(Math.random() * (max - min + 1) + min), id: index}
            }));

            return {data, total: data.length};
        }

        return (await Axios.post<GetInventoryResponse>(
            `/api/upgrade/items`, {
                upgradeDropsAmount,
                offset,
                limit,
                sort,
                minPrice
            }
        )).data;
    }

    if (process.env.NODE_ENV === 'development') {
        const data = MOCK_INVENTORY.map((drop, index) => ({
            ...drop,
            id: index,
            item: {...drop.item, id: index}
        }));

        return {data, total: data.length};
    }

    return (await Axios.post<GetInventoryResponse>(
        `/api/user/${userId}/inventory`, {
            filterBy,
            visibility,
            sort,
            source,
            minPrice,
            filterFreeCaseDrops,
            filterActiveWithdraws,
            offset,
            limit
        }
    )).data;
}
