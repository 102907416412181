
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TG_LINK, VK_LINK } from '@/constants/socialLinks';

@Component({ components: {} })
export default class PadButton extends Vue {
  @Prop() type: string;
  @Prop({ required: false }) to: string;
  @Prop({ type: String, default: 's' }) size: string;

  params = {
    event: { title: 'Задания', icon: 'tasks' },
    cases: { title: 'Кейсы', icon: 'case-2' },
    promotions: { title: 'Лавка', icon: 'promotions' },
    contract: { title: 'Контракт', icon: 'contracts' },
    tg: { title: 'Раздачи', icon: 'telegram' },
    vk: { title: 'Конкурсы', icon: 'vkontakte' },
    upgrade: { title: 'Апгрейд', icon: 'upgrades' }
  };

  get buttonParams() {
    return this.params[this.type];
  }

  goTo(dest: string) {
    switch (true) {
      case dest === 'cases':
        this.$router.push('/');
        break;
      case dest === 'tg':
        return window.open(TG_LINK, '_blank').focus();
      case dest === 'vk':
        return window.open(VK_LINK, '_blank').focus();
      default:
        this.$router.push('/' + dest);
        break;
    }
  }
}
