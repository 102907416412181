import { UserDrop } from '@/types/User';
import { MOCK_GET_DROPS } from '@/requests/Drops/mockGetDrops';

export const MOCK_INVENTORY: UserDrop[] = [
    {
        id: 1,
        item: {
            id: 1,
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 333,
            color: 'e4ae39',
            type: 'item',
            name: 'Hummer Hummer Sword Sword',
        },
        source: 'contract',
        case: {id: 59, image: 'гифт_1627057665359.png', name: ''},
        status: 1,
        offer: {
            status: 1,
            trade: '111',
            sentAt: new Date().toISOString()
        }
    },
    {
        id: 2,
        item: {
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 200,
            color: 'e4ae39',
            type: 'money',
            name: 'Sword',
            id: 1
        },
        source: 'case',
        case: {id: 35, image: 'гифт_1627057665359.png', name: '', isManaCase: true},
        status: 1,
        offer: {
            status: 1
        }
    },
    {
        id: 3,
        item: {
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 50,
            color: 'e4ae39',
            type: 'item',
            name: 'Hummer',
            id: 2
        },
        source: 'case',
        case: {id: 35, image: 'гифт_1627057665359.png', name: ''},
        status: 1,
        offer: {
            status: 1
        }
    },
    {
        id: 4,
        item: {
            id: 1,
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 50,
            color: 'e4ae39',
            type: 'item',
            name: 'Hummer',
        },
        source: 'gift',
        status: 0,
    },
    {
        id: 5,
        item: {
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 200,
            color: 'e4ae39',
            type: 'item',
            name: 'Sword',
            id: 1
        },
        source: 'upgrade',
        case: {id: 35, image: 'гифт_1627057665359.png', name: ''},
        status: 0,
    },
    {
        id: 6,
        item: {
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 50,
            color: 'e4ae39',
            type: 'item',
            name: 'Hummer',
            id: 2
        },
        source: 'contract',
        case: {id: 35, image: 'гифт_1627057665359.png', name: ''},
        status: 0,
    }, {
        id: 7,
        item: {
            id: 1,
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 50,
            color: 'e4ae39',
            type: 'item',
            name: 'Hummer',
        },
        source: 'gift',
        status: 0,
    },
    {
        id: 8,
        item: {
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 200,
            color: 'e4ae39',
            type: 'item',
            name: 'Sword',
            id: 1
        },
        source: 'case',
        case: {id: 35, image: 'гифт_1627057665359.png', name: ''},
        status: 0,
    },
    {
        id: 9,
        item: {
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 50,
            color: 'e4ae39',
            type: 'item',
            name: 'Hummer',
            id: 2
        },
        source: 'upgrade',
        case: {id: 35, image: 'гифт_1627057665359.png', name: ''},
        status: 0,
    }, {
        id: 10,
        item: {
            id: 1,
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 50,
            color: 'e4ae39',
            type: 'item',
            name: 'Etot',
        },
        source: 'gift',
        status: 0,
    },
    {
        id: 11,
        item: {
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 200,
            color: 'e4ae39',
            type: 'item',
            name: 'Sword',
            id: 1
        },
        source: 'case',
        case: {id: 35, image: 'гифт_1627057665359.png', name: ''},
        status: 0,
    },
    {
        id: 12,
        item: {
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 50,
            color: 'e4ae39',
            type: 'item',
            name: 'Hummer Hummer Hummer Hummer Hummer',
            id: 2
        },
        source: 'case',
        case: {id: 35, image: 'гифт_1627057665359.png', name: ''},
        status: 0,
    }, {
        id: 13,
        item: {
            id: 1,
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 50,
            color: 'e4ae39',
            type: 'item',
            name: 'Hummer Hummer Hummer Hummer',
        },
        source: 'gift',
        status: 0,
    },
    {
        id: 14,
        item: {
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 200,
            color: 'e4ae39',
            type: 'item',
            name: 'Sword',
            id: 1
        },
        source: 'case',
        case: {id: 35, image: 'гифт_1627057665359.png', name: ''},
        status: 0,
    },
    {
        id: 15,
        item: {
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 50,
            color: 'e4ae39',
            type: 'item',
            name: 'Hummer',
            id: 2
        },
        source: 'case',
        case: {id: 35, image: 'гифт_1627057665359.png', name: ''},
        status: 0,
    }, {
        id: 16,
        item: {
            id: 1,
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 50,
            color: 'e4ae39',
            type: 'item',
            name: 'Hummer',
        },
        source: 'gift',
        status: 0,
    },
    {
        id: 17,
        item: {
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 200,
            color: 'e4ae39',
            type: 'item',
            name: 'Sword',
            id: 1
        },
        source: 'case',
        case: {id: 35, image: 'гифт_1627057665359.png', name: ''},
        status: 0,
    },
    {
        id: 18,
        item: {
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
            price: 50,
            color: 'e4ae39',
            type: 'item',
            name: 'Hummer',
            id: 2
        },
        source: 'case',
        case: {id: 35, image: 'гифт_1627057665359.png', name: ''},
        status: 0,
    },
].map((drop) => ({
    ...drop,
    item: {...drop.item, image: MOCK_GET_DROPS[Math.floor(Math.random() * MOCK_GET_DROPS.length)].item.image}
}) as UserDrop);
