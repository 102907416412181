
import { Component, Prop, Vue } from 'vue-property-decorator';
import PadButton from '@/components/Buttons/PadButton.vue';

@Component({
  components: {
    EmptyInventoryButtons,
    PadButton,
  },
})
export default class EmptyInventoryButtons extends Vue {
  @Prop({ type: String, default: 's' }) btnSize: string;
}
