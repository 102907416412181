
import Switcher, { SwitcherOption } from '@/components/Switcher.vue';
import TextHeader from '@/components/Layout/TextHeader.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  InventoryFilterKeys,
  InventorySortKeys,
} from '@/requests/Inventory/getInventory/getInventory';

@Component({
  components: {
    Switcher,
    TextHeader,
  },
})
export default class InventoryHeader extends Vue {
  @Prop({ type: String, default: 'Предметы' }) title: string;
  @Prop({ type: Boolean, default: false }) showFilters: boolean;
  @Prop({ type: Boolean, default: false }) showPriceFilter: boolean;
  @Prop({ type: String, required: true }) filterBy: InventoryFilterKeys;
  @Prop({ type: String, required: true }) sortBy: InventorySortKeys;
  @Prop({ type: Array, required: false }) sortingOptions: SwitcherOption[];

  minPrice: number = 0;

  @Watch('minPrice')
  onMinPriceChange() {
    this.$emit('setMinPrice', this.minPrice);
  }
}
