import Axios from 'axios';
import axiosRetry from 'axios-retry';
import { Item } from '@/types/Item';
import { MOCK_GET_DROPS } from '@/requests/Drops/mockGetDrops';

axiosRetry(Axios, {retries: 4, retryDelay: axiosRetry.exponentialDelay});

export default async function getUpgradeItem(upgradeDropsAmount: number, coefficient: number): Promise<Item | null> {
    if (process.env.NODE_ENV === 'development') {
        const randomItem = MOCK_GET_DROPS[Math.floor(Math.random() * MOCK_GET_DROPS.length)].item;
        return {
            id: Math.floor(Math.random() * 100),
            ...randomItem,
            name: 'Selected Item Name Long Bla',
            price: Math.floor(upgradeDropsAmount * coefficient)
        } as Item;
    }

    return (await Axios.post<{ item: Item | null }>(
        `/api/upgrade/item`, {
            upgradeDropsAmount,
            coefficient
        }
    )).data.item;
}
