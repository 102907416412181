
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '../Buttons/Button.vue';

@Component({
  components: {Button},
})
export default class Banner extends Vue {
  @Prop({type: String}) btnText: string;
  @Prop({type: Boolean, required: false, default: false}) btnDisabled: boolean;

  actionClick() {
    this.$emit('onBtnClick');
  }
}
