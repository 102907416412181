
import { Component, Ref, Vue } from 'vue-property-decorator';

import Breadcrumbs from '../components/Layout/Breadcrumbs.vue';
import Banner from '../components/Upgrade/Banner.vue';
import UpgradeBet from '../components/Upgrade/UpgradeBet.vue';
import UpgradePlay from '../components/Upgrade/UpgradePlay.vue';
import SelectedUpgradeItem from '../components/Upgrade/SelectedUpgradeItem.vue';
import { Item } from '@/types/Item';
import DropInventory from '@/components/DropInventory/DropInventory.vue';
import ItemCheckedHover from '@/components/Item/ItemCheckedHover.vue';
import UpgradeEmptyInventory from '@/components/DropInventory/EmptyInventory/UpgradeEmptyInventory.vue';
import { UserDrop } from '@/types/User';
import { getEventNotification } from '@/helpers/getNotification';
import createUpgrade from '@/requests/Upgrade/createUpgrade';
import sellItem from '@/requests/Withdraw/sellItem/sellItem';

export type UpgradeType = 'items' | 'mana' | 'balance';

@Component({
  components: {
    ItemCheckedHover,
    DropInventory,
    Breadcrumbs,
    UpgradeBet,
    UpgradePlay,
    SelectedUpgradeItem,
    Banner,
    UpgradeEmptyInventory,
  },
})
export default class Upgrade extends Vue {
  upgradeType: UpgradeType = 'items';
  manaAmount: number = 0;
  balanceAmount: number = 0;
  upgradeDrops: UserDrop[] = [];
  selectedUpgradeItem: Partial<Item> | null = null;
  maxUpgradeableDropsCount = 10;
  upgradeInProgress: boolean = false;
  upgradeResult: Boolean | null = null;
  wonDrop: UserDrop = null;
  blockSellRequest: boolean = false;
  @Ref('dropInventory') readonly dropInventory: DropInventory;
  @Ref('systemInventory') readonly systemInventory: DropInventory;
  @Ref('upgradePlay') readonly upgradePlay: UpgradePlay;

  created() {
    this.$setPageTitle('Апгрейды | DOTALOOT');
  }

  selectUpgradeType(type: UpgradeType) {
    if (this.upgradeInProgress || this.upgradeType === type) return;

    this.upgradeType = type;
    this.cleanUpgrade();
  }

  updateAmount(amount: number) {
    if (this.upgradeType === 'mana') {
      this.manaAmount = amount;
    } else {
      this.balanceAmount = amount;
    }
  }

  async createUpgrade() {
    try {
      if (this.upgradeInProgress || !this.selectedUpgradeItem) return;

      const betDropIds =
          this.upgradeType === 'items'
              ? this.upgradeDrops.map(drop => drop.id)
              : undefined;
      const betBalance =
          this.upgradeType === 'balance' ? this.balanceAmount : undefined;
      const betMana = this.upgradeType === 'mana' ? this.manaAmount : undefined;

      if ((!betDropIds || !betDropIds.length) && !betBalance && !betMana)
        return;

      this.upgradeInProgress = true;
      const {error, wonDrop, user, eventCompletedTaskNotification} =
          await createUpgrade(
              {betDropIds, betBalance, betMana},
              this.selectedUpgradeItem.id
          );

      if (error) {
        this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Апгрейд',
          text: error,
        });

        return;
      }

      if (eventCompletedTaskNotification) {
        this.$notify(getEventNotification(eventCompletedTaskNotification));
      }
      this.$store.commit('user/update', user || {});

      return wonDrop;
    } catch (e) {
      this.upgradeInProgress = false;
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'error',
        title: 'Апгрейд',
        text: 'Произошла неизвестная ошибка при создании апгрейда. Пожалуйста, попробуйте позже.',
      });
    }
  }

  async sellDrop(id: number) {
    if (!this.user || this.blockSellRequest) return;

    try {
      const {error, user} = await sellItem(id);

      if (error) {
        this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Инвентарь',
          text: error,
        });

        return;
      }

      this.$store.commit('user/update', user);
      this.cleanUpgrade();
      this.dropInventory.removeDrop(id);
      this.upgradePlay.reset();
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'success',
        title: 'Инвентарь',
        text: 'Ваш выигрыш из апгрейда успешно продан!',
      });
    } catch (e) {
      console.log(e);
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'error',
        title: 'Инвентарь',
        text: 'Произошла неизвестная ошибка при продаже предмета, попробуйте позже.',
      });
    } finally {
      this.blockSellRequest = false;
    }
  }

  cleanUpgrade() {
    this.upgradeDrops.map(drop => this.dropInventory.toggleDrop(drop.id));
    this.upgradeDrops = [];
    this.upgradeResult = null;
  }

  toggleDrop(drop: UserDrop) {
    if (this.upgradeType !== 'items') return;

    const dropInUpgradeIndex = this.upgradeDrops.findIndex(
        dropInContract => dropInContract.id === drop.id
    );

    if (dropInUpgradeIndex > -1) {
      this.upgradeDrops.splice(dropInUpgradeIndex, 1);
    } else {
      if (this.upgradeDrops.length >= this.maxUpgradeableDropsCount) return;

      this.upgradeDrops.unshift(drop);
    }

    this.dropInventory.toggleDrop(drop.id);
  }

  toggleUpgradeItem(drop: UserDrop | Item, force?: boolean) {
    const item = 'item' in drop ? drop.item : drop;
    const id = drop.id;

    if (id !== this.selectedUpgradeItem?.id) {
      this.selectedUpgradeItem = item;
      if (!this.systemInventory.inventoryDrops.find(_drop => _drop.id === id)) {
        this.systemInventory.addDrop({item, id});
      }
    } else {
      if (force) return;
      this.selectedUpgradeItem = null;
    }

    this.systemInventory.toggleDrop(id, true);
  }

  autofillUpgrade() {
    const length = this.maxUpgradeableDropsCount - this.upgradeDrops.length;

    if (length <= 0) return;

    this.dropInventory.inventoryDrops
        .filter(drop => !drop.checked)
        .slice(0, length)
        .map(drop => this.dropInventory.toggleDrop(drop.id));
    this.upgradeDrops = this.dropInventory.inventoryDrops
        .filter(drop => drop.checked)
        .slice(0, 10);
  }

  handlePlayRolled(wonDrop: UserDrop | null) {
    this.dropInventory.removeDrop(this.upgradeDrops.map(drop => drop.id));
    this.upgradeDrops = [];

    if (wonDrop) {
      this.dropInventory.addDrop(wonDrop);
      this.wonDrop = wonDrop;
    }
    this.upgradeResult = !!wonDrop;
    this.upgradeInProgress = false;
  }

  resetUpgrade() {
    this.cleanUpgrade();
    this.selectedUpgradeItem = null;
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
        ? this.$store.state.user
        : null;
  }

  get upgradeAmount() {
    switch (this.upgradeType) {
      case 'items':
        return this.upgradeDrops.reduce(
            (sum, drop) => sum + drop.item.price,
            0
        );
      case 'mana':
        return Math.floor(this.manaAmount / 100);
      case 'balance':
        return this.balanceAmount;
    }
  }

  get chance() {
    if (!this.upgradeAmount || !this.selectedUpgradeItem) return 0;

    const chance = Number(
        (
            (this.upgradeAmount / this.selectedUpgradeItem.price) *
            0.93 *
            100
        ).toFixed(2)
    );

    if (chance < 0) return 0;
    if (chance > 100) return 100;

    return chance;
  }

  get isValidChance() {
    return this.chance >= 9 && this.chance <= 80;
  }

  get isActionsActive() {
    return (
        this.$store.getters['user/isAuthorized'] &&
        this.upgradeAmount > 0 &&
        !this.upgradeInProgress &&
        this.upgradeResult === null
    );
  }

  bannerButtonAction() {
    if (!this.user || this.upgradeInProgress) {
      return;
    }

    this.autofillUpgrade();
  }

  get isBannerButtonDisabled() {
    return !this.user || this.upgradeInProgress || this.upgradeResult !== null;
  }

  get bannerButtonText() {
    if (!this.user) {
      return 'Авторизируйтесь';
    }

    return 'Добавить все предметы';
  }
}
