var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wrapper upgrade-page",style:({ overflow: 'visible' })},[_c('Breadcrumbs',{attrs:{"paths":[{ href: '/', title: 'Главная' }, { title: 'Апгрейды' }]}}),_c('Banner',{attrs:{"btnText":_vm.bannerButtonText,"btnDisabled":_vm.isBannerButtonDisabled},on:{"onButtonClick":function($event){return _vm.bannerButtonAction()}}}),_c('div',{staticClass:"top-panels"},[_c('UpgradeBet',{attrs:{"upgradeType":_vm.upgradeType,"drops":_vm.upgradeDrops,"amount":_vm.upgradeAmount,"wonDrop":_vm.wonDrop,"upgradeResult":_vm.upgradeResult,"blocked":!!_vm.user,"sellDropFn":_vm.sellDrop},on:{"selectUpgradeType":_vm.selectUpgradeType,"updateAmount":_vm.updateAmount,"removeItem":_vm.toggleDrop,"clean":_vm.cleanUpgrade,"autofill":_vm.autofillUpgrade}}),_c('UpgradePlay',{ref:"upgradePlay",attrs:{"chance":_vm.chance,"isPlayActive":_vm.isActionsActive && _vm.isValidChance,"isUpgradeInProgress":_vm.upgradeInProgress,"createUpgradeFn":_vm.createUpgrade,"amount":_vm.upgradeAmount},on:{"playRolled":_vm.handlePlayRolled,"reset":_vm.resetUpgrade}}),_c('SelectedUpgradeItem',{attrs:{"item":_vm.selectedUpgradeItem,"upgradeDropsAmount":_vm.upgradeAmount,"upgradeResult":_vm.upgradeResult,"isActive":_vm.isActionsActive},on:{"selectItem":item => _vm.toggleUpgradeItem(item, true)}})],1),_c('div',{staticClass:"upgrade-items"},[_c('DropInventory',{ref:"dropInventory",class:{
        'upgrade-drop-inventory': true,
        'upgrade-drop-inventory--disabled': _vm.upgradeType !== 'items',
      },attrs:{"visibility":"personal","showPriceFilter":"","userId":_vm.user?.uuid,"rowSize":4,"filterFreeCaseDrops":"","filterActiveWithdraws":"","blockItems":_vm.upgradeType !== 'items' || _vm.upgradeInProgress || _vm.upgradeResult !== null,"sortingOptions":[
        { code: 'priceDesc', name: 'Дорогие' },
        { code: 'priceAsc', name: 'Дешевые' },
      ]},on:{"dropClicked":_vm.toggleDrop},scopedSlots:_vm._u([{key:"topHint",fn:function(){return [_c('div',{staticClass:"inventory-top-hint"},[_vm._v(" Выберите предмет из вашего инвентаря ")])]},proxy:true},{key:"headerLeft",fn:function(){return [_c('div',{staticClass:"inventory-small-title"},[_vm._v("Ваши предметы")])]},proxy:true},(_vm.upgradeType === 'items')?{key:"itemAfter",fn:function({ checked }){return [_c('ItemCheckedHover',{attrs:{"mode":"plus","checked":checked}})]}}:null,{key:"noItems",fn:function(){return [_c('UpgradeEmptyInventory')]},proxy:true}],null,true)}),_c('DropInventory',{ref:"systemInventory",staticClass:"upgrade-drop-inventory",attrs:{"visibility":"upgrade","showPriceFilter":"","rowSize":4,"blockItems":_vm.upgradeInProgress || _vm.upgradeResult !== null,"sortingOptions":[
        { code: 'priceDesc', name: 'Дорогие' },
        { code: 'priceAsc', name: 'Дешевые' },
      ],"upgradeDropsAmount":_vm.upgradeAmount || 300},on:{"dropClicked":_vm.toggleUpgradeItem},scopedSlots:_vm._u([{key:"topHint",fn:function(){return [_c('div',{staticClass:"inventory-top-hint"},[_vm._v(" Выберите предмет на который хотите обновиться ")])]},proxy:true},{key:"headerLeft",fn:function(){return [_c('div',{staticClass:"inventory-small-title"},[_vm._v("Для апгрейда")])]},proxy:true},{key:"itemAfter",fn:function({ checked }){return [_c('ItemCheckedHover',{attrs:{"mode":"plus","checked":checked}})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }