
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import Item from '../Item/Item.vue';
import SellDrop from '@/components/SellDrop.vue';
import PadButton from '@/components/Buttons/PadButton.vue';
import { UserDrop } from '@/types/User';
import { ruDeclension } from '@/helpers/ruDeclension';

import VueRangeSlider from 'vue-slider-component';
import { UpgradeType } from '@/pages/Upgrade.vue';
import 'vue-slider-component/theme/default.css';

@Component({
  components: {
    Item,
    VueRangeSlider,
    SellDrop,
    PadButton,
  },
})
export default class UpgradeBet extends Vue {
  @Prop({ type: String, required: true }) upgradeType: UpgradeType;
  @Prop({ type: Array, required: true }) drops: UserDrop[];
  @Prop({ type: Boolean, required: false }) upgradeResult: boolean | null;
  @Prop({ type: Function, required: true }) sellDropFn: (
    id: number
  ) => Promise<void>;
  @Prop() wonDrop: UserDrop | null;
  activeItemIndex: number = 0;
  inputAmount: number = 0;
  @Ref('slider') readonly rangeSlider: VueRangeSlider;

  clean() {
    if (this.upgradeType === 'items') {
      this.$emit('clean');
    } else {
      this.inputAmount = this.minRange;
    }
  }

  autofill() {
    if (this.upgradeType === 'items') {
      this.$emit('autofill');
    } else {
      this.inputAmount = this.maxRange;
    }
  }

  decrement() {
    if (
      this.upgradeType === 'items' &&
      this.activeItemIndex < this.drops.length - 1
    ) {
      this.activeItemIndex++;
    } else if (
      this.upgradeType !== 'items' &&
      this.inputAmount > this.minRange
    ) {
      this.inputAmount--;
    }
  }

  increment() {
    if (this.upgradeType === 'items' && this.activeItemIndex > 0) {
      this.activeItemIndex--;
    } else if (
      this.upgradeType !== 'items' &&
      this.inputAmount < this.maxRange
    ) {
      this.inputAmount++;
    }
  }

  removeItem() {
    this.$emit('removeItem', this.drops[this.activeItemIndex]);
  }

  selectActiveTab(type: UpgradeType) {
    this.$emit('selectUpgradeType', type);
  }

  reset() {
    this.inputAmount = Math.floor(this.maxRange / 2);
    this.activeItemIndex = 0;
  }

  get minRange() {
    const MIN = 1;

    if (this.upgradeType === 'balance') {
      return MIN;
    }

    return MIN * 100;
  }

  get maxRange() {
    const MAX = 40000;
    if (this.upgradeType === 'balance') {
      if (this.userBalance > MAX) {
        return MAX;
      }
      return this.userBalance;
    }

    const MANA_MAX = Math.floor(MAX * 100);
    if (this.userBalance > MANA_MAX) {
      return MANA_MAX;
    }

    return this.userBalance;
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }

  get userBalance() {
    const user = this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
    if (!user) return 0;

    if (this.upgradeType === 'balance') {
      return user.balance;
    } else if (this.upgradeType === 'mana') {
      return user.mana;
    }
  }

  get dropsSum() {
    if (this.drops) {
      let sum = 0;
      this.drops.forEach(d => {
        sum += d.item.price;
      });
      return sum;
    } else return 0;
  }

  @Watch('upgradeType')
  onUpgradeTypeChange() {
    this.reset();
  }

  @Watch('inputAmount')
  onInputAmountChange() {
    this.$emit('updateAmount', this.inputAmount);

    if (this.rangeSlider) {
      this.rangeSlider.setValue(this.inputAmount);
    }
  }

  ruDec(val: number, strings: string[]) {
    return ruDeclension(val, strings);
  }
}
