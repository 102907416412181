
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getDropSellPrice } from '@/helpers/getDropSellPrice';
import Button from '../components/Buttons/Button.vue';
import { UserDrop } from '@/types/User';

@Component({ components: { Button } })
export default class SellDrop extends Vue {
  @Prop(Boolean) checkedOnStart: boolean;
  @Prop() wonDrop: null | UserDrop;

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }

  get sellPrice() {
    return this.wonDrop ? getDropSellPrice(this.wonDrop.item.price) : undefined;
  }

  openMana() {
    // TODO: handle mana modal
    // ModalModule.toggle_modal({ name: 'mana' });
  }

  async sellDrop() {
    this.$emit('sellDrop');
  }
}
