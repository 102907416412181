
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Switcher, { SwitcherOption } from '@/components/Switcher.vue';
import Button from '@/components/Buttons/Button.vue';
import TextHeader from '@/components/Layout/TextHeader.vue';
import Item from '@/components/Item/Item.vue';
import getInventory, {
  InventoryFilterKeys,
  InventorySortKeys,
} from '@/requests/Inventory/getInventory/getInventory';
import { UserDrop } from '@/types/User';
import InventoryHeader from '@/components/DropInventory/InventoryHeader/InventoryHeader.vue';

export type DropSourceSelect = 'all' | 'contract' | 'upgrade' | 'gift';
@Component({
  components: {InventoryHeader, Switcher, Button, Item, TextHeader},
})
export default class DropInventory extends Vue {
  @Prop({type: String, required: false}) userId?: string;
  @Prop({type: String, default: 'Предметы'}) title: string;
  @Prop({type: Boolean, default: false}) showDropSourceSelect: boolean;
  @Prop({type: Boolean, default: false}) showItemStatusFilter: boolean;
  @Prop({type: Boolean, default: false}) showPriceFilter: boolean;
  @Prop({type: Boolean, default: false}) filterFreeCaseDrops: boolean;
  @Prop({type: Boolean, default: false}) filterActiveWithdraws: boolean;
  @Prop({type: Array, required: false}) sortingOptions: SwitcherOption[];
  @Prop({type: String, default: 'public'}) visibility: string;
  @Prop({type: Number, default: 7}) rowSize: number;
  @Prop({type: Number, default: 0}) upgradeDropsAmount: number;
  @Prop({type: Boolean, default: false}) blockItems: boolean;
  @Prop({type: Number, default: 3}) mobileRowSize: number;
  offset: number = 0;
  minPrice: number = 0;
  inventoryFilter: InventoryFilterKeys = 'active';
  inventorySort: InventorySortKeys = 'priceDesc';
  selectedDropSource: DropSourceSelect = 'all';
  isLoadingInventory: boolean = false;
  isInitialLoading: boolean = true;
  inventoryDrops: UserDrop[] = [];
  totalDrops: number = 0;

  mounted() {
    if (this.visibility === 'personal') {
      this.$socket.client.on('change_offer_status', (data: any) => {
        console.log('here socket', data);
        const drop = this.inventoryDrops.find(drop => drop.id === data.dropId);
        if (drop) {
          drop.status = data.dropStatus;
          if (data.offerStatus || data.offerTrade) {
            drop.offer = {
              status: data.offerStatus,
              trade: data.offerTrade,
              type: data.offerType,
            };
          }
        }
      });
    }

    this.getInventory();
  }

  get isPublicProfile() {
    return this.user ? this.userId !== this.user.uuid : false;
  }

  get shouldShowMore() {
    return this.totalDrops > this.inventoryDrops.length;
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
        ? this.$store.state.user
        : null;
  }

  get headerLeftData() {
    return {
      showFilters: this.showItemStatusFilter,
      selectedDropSource: this.selectedDropSource,
      setSelectedDropSource: (source: DropSourceSelect) =>
          (this.selectedDropSource = source),
    };
  }

  async getInventory() {
    try {
      if (this.isLoadingInventory) return;
      if (this.visibility === 'personal' && (!this.user || !this.userId))
        return;

      this.isLoadingInventory = true;
      const {data, total} = await getInventory(
          this.userId,
          this.inventoryFilter,
          this.visibility,
          this.selectedDropSource,
          this.inventorySort,
          this.minPrice,
          this.offset,
          this.searchLimit,
          this.filterActiveWithdraws,
          this.filterFreeCaseDrops,
          this.upgradeDropsAmount
      );
      this.totalDrops = total;
      this.inventoryDrops = [...this.inventoryDrops, ...data];
    } catch (e) {
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'warning',
        title: 'Загрузка инвентаря',
        text: 'Неизвестная ошибка!',
      });
    } finally {
      if (this.isInitialLoading) {
        this.isInitialLoading = false;
      }
      this.isLoadingInventory = false;
    }
  }

  get gridSize() {
    const crs = this.rowSize;
    if (window.outerWidth <= 780)
      return this.inventoryDrops.length <= 3
          ? 3
          : Math.ceil(this.inventoryDrops.length / 3) * 3;
    else
      return this.inventoryDrops.length <= crs
          ? crs * 2
          : Math.ceil(this.inventoryDrops.length / crs) * crs;
  }

  get searchLimit() {
    if (window.outerWidth <= 780) {
      return 6;
    }
    return this.rowSize * 2;
  }

  get searchWatchData() {
    return [
      this.userId,
      this.inventoryFilter,
      this.visibility,
      this.selectedDropSource,
      this.inventorySort,
      this.minPrice,
      this.searchLimit,
      this.filterActiveWithdraws,
      this.filterFreeCaseDrops,
      this.upgradeDropsAmount,
    ];
  }

  addDrop(drop: UserDrop) {
    this.inventoryDrops = [...this.inventoryDrops, drop];
  }

  proceedDropToPendingWithdraw(dropId: number, type: UserDrop['offer']['type']) {
    const dropIdx = this.inventoryDrops.findIndex(({id}) => id === dropId);

    if (dropIdx > -1) {
      const drops = [...this.inventoryDrops];
      drops[dropIdx].status = 3;
      drops[dropIdx].offer = {status: 0, type};

      this.inventoryDrops = drops;
    }
  }

  removeDrop(dropId: number | number[]) {
    if (Array.isArray(dropId)) {
      this.inventoryDrops = this.inventoryDrops.filter(
          ({id}) => !dropId.includes(id)
      );
      return;
    }
    this.inventoryDrops = this.inventoryDrops.filter(({id}) => id !== dropId);
  }

  toggleDrop(dropId: number, force?: boolean) {
    const newInv = this.inventoryDrops.map(drop =>
        drop.id === dropId
            ? {...drop, checked: !drop.checked}
            : {...drop, checked: force ? false : drop.checked}
    );
    this.inventoryDrops = [...newInv];
  }

  showMore() {
    if (this.isLoadingInventory) return;
    if (this.shouldShowMore) {
      this.offset =
          this.offset > this.totalDrops
              ? this.totalDrops
              : this.offset + this.searchLimit;
    }
    this.getInventory();
  }

  changeFilter(filter: InventoryFilterKeys) {
    if (this.isLoadingInventory) return;

    this.inventoryFilter = filter;
  }

  resetInventory() {
    this.offset = 0;
    this.inventoryDrops = [];
    this.getInventory();
  }

  @Watch('searchWatchData')
  handleSearchDataChange() {
    this.resetInventory();
  }

  @Watch('user')
  onUserChange() {
    if (this.visibility !== 'public') {
      this.resetInventory();
    }
  }
}
